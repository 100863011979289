<template>
  <div>
    <v-container>
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form
          @submit.prevent="handleSubmit(nextPage)"
          method="post"
          id="check-login-form"
        >
          <v-row>
<!--            exam title -->
            <v-col cols="12">
              <h5 class="headline">Tiêu đề</h5>
              <ValidationProvider
                name="title"
                rules="required"
                v-slot="{ errors }"
              >
              <v-text-field
                v-model="examMeta.title"
                outlined
                textarea
              ></v-text-field>
                <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <h5 class="headline">Slug</h5>
              <ValidationProvider
                name="slug"
                rules="required"
                v-slot="{ errors }"
              >
              <v-text-field
                v-model="examMeta.slug"
                outlined
                textarea
              ></v-text-field>
                <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <h5 class="headline">Thời gian làm bài thi (phút)</h5>
              <ValidationProvider
                  name="time"
                  rules="required"
                  v-slot="{ errors }"
              >
                <v-text-field
                    placeholder="120 phút"
                    v-model="examMeta.time"
                    outlined
                    textarea
                ></v-text-field>
                <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="4"></v-col>
                <v-col cols="4">
                  <v-btn @click="$router.go(-1)" large medium>Hủy </v-btn>
                  <v-btn
                    class="ma-5"
                    type="submit"
                    elevation="2"
                    large
                    medium
                    color="primary"
                    form="check-login-form"
                  >
                    tiếp theo</v-btn
                  >
                </v-col>
                <v-col cols="4"></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {SUCCESS} from "@/store/alerts.module";
// import CkContent from "@/components/ck-content";
import {
  GET_EXAM_META_DETAIL,
  SAVE_EXAM_META,
  UPDATE_EXAM_META,
} from "@/store/exams.module";

import mixin from '@/store/mixin'


export default {
  components: {
    // CkContent,
  },
  mixins: [mixin],
  data: () => ({
    // items: [
    //   { text: "Topik I", value: 1 },
    //   { text: "Topik II", value: 2 },
    // ],
    // '2020-11-28 00:00:00'
    picker: new Date().toISOString().substr(0, 10),
    timer: "11:15",
    examMeta: {},
  }),
  computed: {
    ...mapGetters({
      exam_meta: "examMetaDetail",
    }),
  },
  watch: {
    exam_meta: {
      deep: true,
      immediate: true,
      handler(self) {
        this.examMeta = Object.assign({}, self);
        this.examMeta.time = 120
      },
    },
    examMeta: {
      deep: true,
      immediate: true,
      handler(self) {
        if(self.title){
          self.slug = this.sanitizeTitle(self.title);
        }
      },
    },
  },
  async created() {
    this.scrollToTop();
    await this.$store.dispatch(GET_EXAM_META_DETAIL, {
      exam_meta_slug: this.$route.query.exam_meta_slug,
    });
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getExamMetaTitle(value) {
      this.examMeta.title = value;
    },
    nextPage() {
      // format day with time
      this.examMeta.start_date_time = this.picker + " " + this.timer;
      if (this.examMeta.id) {
        this.$store
          .dispatch(UPDATE_EXAM_META, {
            exam_meta: this.examMeta,
          })
          .then((data) => {
            // save exam meta success
            if (data.data.status) {
              let exam_meta = data.data.data;
              this.$router.push({
                path: "/exam/type",
                query: {
                  exam_meta_slug: exam_meta.slug,
                },
              });
            }
          });
      }
      if (!this.examMeta.id) {
        this.$store
          .dispatch(SAVE_EXAM_META, {
            exam_meta: this.examMeta,
          })
          .then((data) => {
            // save exam meta success
            if (data.status) {
              let exam_meta = data.data;
              this.$router.push({
                path: "/exam/select-type",
                query: {
                  exam_meta_id: exam_meta.id,
                },
              });
            }else{
              this.$store.dispatch(SUCCESS, {
                type: "error_vote",
                message: data.message
              }, {
                root: true,
              });
            }
          });
      }
    },
    getDatePicker() {
      this.examMeta.start_date_time = this.picker;
    },

  },
};
</script>

<style scoped>
.text-title {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  color: black;
}
</style>
