var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"check-login-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.nextPage)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"headline"},[_vm._v("Tiêu đề")]),_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","textarea":""},model:{value:(_vm.examMeta.title),callback:function ($$v) {_vm.$set(_vm.examMeta, "title", $$v)},expression:"examMeta.title"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"headline"},[_vm._v("Slug")]),_c('ValidationProvider',{attrs:{"name":"slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","textarea":""},model:{value:(_vm.examMeta.slug),callback:function ($$v) {_vm.$set(_vm.examMeta, "slug", $$v)},expression:"examMeta.slug"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"headline"},[_vm._v("Thời gian làm bài thi (phút)")]),_c('ValidationProvider',{attrs:{"name":"time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"120 phút","outlined":"","textarea":""},model:{value:(_vm.examMeta.time),callback:function ($$v) {_vm.$set(_vm.examMeta, "time", $$v)},expression:"examMeta.time"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"large":"","medium":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Hủy ")]),_c('v-btn',{staticClass:"ma-5",attrs:{"type":"submit","elevation":"2","large":"","medium":"","color":"primary","form":"check-login-form"}},[_vm._v(" tiếp theo")])],1),_c('v-col',{attrs:{"cols":"4"}})],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }